var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "body-layout",
    [
      _c(
        "div",
        {
          staticClass: "actions flex",
          attrs: { slot: "actions" },
          slot: "actions",
        },
        [
          _vm.selectedRowKeys.length
            ? _c(
                "a-button",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: "system:termtemplate:list:button:add",
                      expression: "'system:termtemplate:list:button:add'",
                    },
                  ],
                  staticClass: "mr-3",
                  attrs: { type: "", icon: "copy" },
                  on: { click: _vm.batchCopy },
                },
                [_vm._v("创建副本")]
              )
            : _vm._e(),
          _vm.selectedRowKeys.length
            ? _c(
                "a-button",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: "system:termtemplate:list:button:delete",
                      expression: "'system:termtemplate:list:button:delete'",
                    },
                  ],
                  staticClass: "mr-3",
                  attrs: { type: "danger", icon: "delete" },
                  on: { click: _vm.batchDel },
                },
                [_vm._v("删除")]
              )
            : _vm._e(),
          _c(
            "a-button",
            {
              directives: [
                {
                  name: "has",
                  rawName: "v-has",
                  value: "system:termtemplate:list:button:add",
                  expression: "'system:termtemplate:list:button:add'",
                },
              ],
              attrs: { type: "primary", icon: "plus" },
              on: { click: _vm.openAddForm },
            },
            [_vm._v("新建")]
          ),
        ],
        1
      ),
      _c("a-table", {
        ref: "table",
        attrs: {
          size: "middle",
          rowKey: "id",
          columns: _vm.columns,
          dataSource: _vm.dataSource,
          customRow: _vm.clickRow,
          pagination: _vm.ipagination,
          loading: _vm.loading,
          scroll: { x: "max-content", y: "calc(100vh - 353px)" },
          rowSelection: {
            selectedRowKeys: _vm.selectedRowKeys,
            onChange: _vm.onSelectChange,
            columnWidth: 60,
          },
        },
        on: { change: _vm.handleTableChange },
        scopedSlots: _vm._u([
          {
            key: "departments",
            fn: function (record) {
              return [
                _c(
                  "div",
                  { staticClass: "flex" },
                  _vm._l(record.departments, function (item) {
                    return _c("a-tag", { key: item.departId }, [
                      _vm._v(_vm._s(item.departName)),
                    ])
                  }),
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c("temp-detail", {
        attrs: {
          open: _vm.drawerShow,
          "cur-row": _vm.curRow,
          domain: _vm.domain,
          isForm: _vm.isNewForm,
        },
        on: { closed: _vm.drawerClosed },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }