<template>
  <body-layout>
    <div class="actions flex" slot="actions">
      <a-button
        type=""
        class="mr-3"
        icon="copy"
        @click="batchCopy"
        v-if="selectedRowKeys.length"
        v-has="'system:termtemplate:list:button:add'"
        >创建副本</a-button
      >
      <a-button
        type="danger"
        class="mr-3"
        icon="delete"
        @click="batchDel"
        v-if="selectedRowKeys.length"
        v-has="'system:termtemplate:list:button:delete'"
        >删除</a-button
      >
      <a-button type="primary" @click="openAddForm" icon="plus" v-has="'system:termtemplate:list:button:add'"
        >新建</a-button
      >
    </div>

    <a-table
      ref="table"
      size="middle"
      rowKey="id"
      :columns="columns"
      :dataSource="dataSource"
      :customRow="clickRow"
      :pagination="ipagination"
      :loading="loading"
      :scroll="{ x: 'max-content', y: 'calc(100vh - 353px)' }"
      :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, columnWidth: 60 }"
      @change="handleTableChange"
    >
      <template slot="departments" slot-scope="record">
        <div class="flex">
          <a-tag v-for="item in record.departments" :key="item.departId">{{ item.departName }}</a-tag>
        </div>
      </template>
    </a-table>
    <temp-detail
      :open="drawerShow"
      :cur-row="curRow"
      :domain="domain"
      :isForm="isNewForm"
      @closed="drawerClosed"
    ></temp-detail>
  </body-layout>
</template>

<script>
import { getAction, postAction, deleteAction } from '@/api/manage'
import AddTemplate from './modules/AddTemplate'
import TempDetail from './modules/terms/TempDetail'
import { JeecgListMixin } from '@/mixins/JeecgListMixin'
const defaultFields = {
  fieldName: '',
  fieldClassId: '',
  required: false
}
export default {
  name: 'TermsList',
  mixins: [JeecgListMixin],
  components: {
    AddTemplate,
    TempDetail
  },
  data() {
    return {
      showTempModal: false,
      isNew: false,
      fieldsList: [Object.assign({}, defaultFields)],
      curTempId: 0,
      columns: [
        {
          title: '序号',
          dataIndex: 'sort',
          width: 60
        },
        {
          title: '名称',
          width: 170,
          dataIndex: 'title'
        },
        {
          title: '说明',
          width: 200,
          dataIndex: 'description'
        },
        {
          title: '适用部门',
          scopedSlots: { customRender: 'departments' }
        }
      ],
      domain: 'TERMS',
      url: {
        /* list:{ path:'/productDetail/page'}, */
        list: { path: '/term/type/page' },
        deleteBatch: '/term/type/delete',
        copyBatch: 'productDetail/copy'
      }

      // clickRow: (record, index) => ({
      //   on: {
      //     click: () => {
      //       this.showTempModal = true;
      //       this.curTempId = record.templateId;
      //     }
      //   }
      // }),
    }
  },
  computed: {},
  /*   methods: {
    tempModalClosed(val) {
      this.showTempModal = false
      this.isNew = false
      this.getTemplateList()
    },
    showAddTemp() {
      this.showTempModal = true
      this.isNew = true
    },
    onSelectTempChange(selected) {
      console.log(selected)
      this.selectedTempKeys = selected
    },
    delSelectedTempl() {
      var param = this.selectedTempKeys.join(',')
      const self = this
      console.log(param)
      this.$confirm({
        title: '警告',
        content: '您确定要删除所选模版吗？',
        onOk() {
          deleteAction('/terms/delete_template_batch', { ids: param }).then((res) => {
            if (res.success) {
              self.$message.success(res.message)
              self.getTemplateList()
            } else {
              self.$message.error(res.message)
            }
          })
        },
      })
    },
  }, */
  created() {},
  methods: {
    loadData(arg, id, callback) {
      if (!this.url.list) {
        this.$message.error('请设置url.list属性!')
        return
      }
      //加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.ipagination.current = 1
      }
      var params = this.getQueryParams() //查询条件
      if (this.id) params.id = this.id
      if (id) params.id = id
      this.loading = true
      params.current = params.pageNo
      params.size = params.pageSize
      getAction(this.url.list.path, params).then(res => {
        console.log('JeecgListMixin loadData getAction over', { res })
        if (res.success) {
          //update-begin---author:zhangyafei    Date:20201118  for：适配不分页的数据列表------------
          this.dataSource = res.data.records || res.data
          if (res.data.total) {
            this.ipagination.total = res.data.total - 0
          } else {
            this.ipagination.total = 0
          }
          //update-end---author:zhangyafei    Date:20201118  for：适配不分页的数据列表------------

          // 字体预览需要往head里插入css样式
          if (this.isInserstCss) {
            batchStyleInsertHeader(this.dataSource)
          }
          this.dataSource= this.dataSource.map(item=>{return {sort:item.termType.sort,title:item.termType.name,description:item.termType.description,departments:item.departs,...item.termType}})
          this.$emit('loadData', this.dataSource)
        }
        if (res.code === 510) {
          this.$message.warning(res.message)
        }
        this.loading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .top-tabs {
  margin-top: -24px;
}
/deep/ .border-bottom {
  border: none !important;
}
</style>
